<template>
  <div>
    <div class="text-center" v-if="!sucesso">
      <v-icon class="tw-animate-spin tw-text-pink-600" size="50"
        >mdi-loading</v-icon
      >
    </div>
    <div v-else>
      <div class="tw-px-9 tw-py-1 tw-items-center tw-justify-left tw-flex">
        <button
          @click="goBack()"
          class="tw-bg-transparent tw-rounded-full tw-fill-current tw-group tw-text-text-gray-700 tw-text-xl"
        >
          <v-icon
            size="25"
            color="fill"
            class="tw-text-gray-700 tw-fill-current tw-stroke-current group-hover:tw-text-pink-700"
            >mdi-arrow-left</v-icon
          >
          <div
            class="tw-inline-block tw-text-gray-500 tw-font-light tw-text-base tw-ml-2"
          >
            VOLTAR
          </div>
        </button>
      </div>
      <div
        class="tw-w-5/6 tw-mx-auto tw-shadow-sm tw-mt-5 tw-rounded-md tw-bg-white"
      >
        <div class="tw-flex tw-items-center tw-text-center tw-p-5">
          <div class="tw-px-8">
            <div>
              <v-icon size="50" class="tw-text-gray-500 tw-stroke-current"
                >mdi-text-box</v-icon
              >
            </div>
            <div class="tw-mt-3">
              <button
                class="tw-px-3 tw-py-2 tw-mx-auto tw-font-bold tw-uppercase tw-rounded tw-text-white tw-bg-pink-600 hover:tw-bg-pink-700 tw-transition-colors tw-duration-300 tw-font-mono tw-text-xs"
                @click="gotoLive()"
              >
                Live Totem
              </button>
            </div>
          </div>
          <div
            class="tw-text-left tw-justify-start tw-items-start tw-self-start"
          >
            <div class="tw-font-bold tw-text-gray-600 tw-text-md">
              {{ getTotem.nome }}
            </div>
            <div class="tw-font-normal tw-pt-2 tw-text-sm tw-text-gray-500">
              {{ getTotem.descricao }}
            </div>
            <div
              class="tw-font-normal tw-text-sm tw-text-gray-500 tw-font-sans tw-mt-2"
            >
              Compras iniciadas hoje {{ getComprasDeHoje }}
            </div>
          </div>
        </div>
      </div>
      <div class="tw-py-5 tw-text-center sm:tw-text-right"></div>
      <div class="tw-w-5/6 tw-mx-auto">
        <div class="tw-text-gray-900 tw-text-xl tw-font-normal tw-py-5">
          Registro de compras
        </div>
        <div
          id="list"
          class="tw-rounded-md tw-bg-white tw-p-0 tw-shadow-md tw-mb-5"
        >
          <div
            class="tw-text-xs tw-uppercase item tw-font-medium tw-tracking-wide tw-text-gray-600 tw-hidden sm:tw-grid tw-bg-white tw-rounded-t-md"
          >
            <div class="tw-px-0">Data / Hora</div>
            <div class="tw-px-0">Etapa</div>
            <div class="tw-px-0">Fotos</div>
            <div class="tw-px-0">Tipo de Foto</div>
            <div class="tw-px-0">Valor</div>
          </div>
          <div
            :key="index"
            v-for="(compra, index) in getCompras"
            class="item tw-overflow-x-auto"
          >
            <div class="itemSection">
              <div class="tw-block sm:tw-hidden tw-p-1 tw-font-bold">
                Data/Hora
              </div>
              <div class="tw-text-sm">
                {{ compra.keep_alive.toDate() | getDataPayload }}
              </div>
            </div>
            <div class="itemSection">
              <div class="tw-block sm:tw-hidden tw-p-1 tw-font-bold">Etapa</div>
              <div class="tw-text-sm">{{ compra.etapa | filtraEtapa }}</div>
            </div>
            <div class="itemSection">
              <div class="tw-block sm:tw-hidden tw-p-1 tw-font-bold">
                Quantidade de Fotos
              </div>
              <div class="tw-text-sm">{{ compra.qtde_foto }}</div>
            </div>
            <div class="itemSection">
              <div class="tw-block sm:tw-hidden tw-p-1 tw-font-bold">
                Tipo de foto
              </div>
              <div class="tw-text-sm">{{ compra.tipo_foto | existeDados }}</div>
            </div>
            <div class="itemSection">
              <div class="tw-block sm:tw-hidden tw-p-1 tw-font-bold">Valor</div>
              <div class="tw-text-sm">{{ compra.valor | currency }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {
  getDocs,
  collection,
  query,
  where,
  limit,
  orderBy,
} from "firebase/firestore";
import { db } from "@/service/firebase";
import { etapaName } from "@/constants/etapaCompra";
export default {
  beforeMount() {
    this.verificaExistenciaLocal();
  },
  data() {
    return {
      countFail: 0,
      sucesso: false,
      listaCompras: [],
    };
  },
  filters: {
    existeDados(valor) {
      if (!valor) {
        return "Não definido";
      } else {
        return valor;
      }
    },
    getDataPayload(val) {
      return moment(val).format("DD/MM/YYYY - HH:mm:ss");
    },
    filtraEtapa(valor) {
      if (etapaName[valor]) {
        return etapaName[valor];
      } else {
        return valor;
      }
    },
  },
  methods: {
    gotoLive() {
      this.$router.push(`/dashboard/totem/${this.$route.params.id}/live`);
    },
    async requestCompras() {
      this.listaCompras = [];
      let referencia = collection(db, "compras");
      let totemID = this.$route.params.id;
      let q = query(
        referencia,
        where("totem_id", "==", totemID),
        orderBy("keep_alive", "desc"),
        limit(20)
      );
      var snapshot = await getDocs(q);
      snapshot.forEach((item) => {
        let proposed = { id: item.id, ...item.data() };
        this.listaCompras.push(proposed);
      });
    },
    verificaExistenciaLocal() {
      let totens = this.$store.getters["dashboard/getTotens"];
      let totemSearch = totens.find((item) => item.id == this.$route.params.id);
      if (totemSearch) {
        this.sucesso = true;
        this.requestCompras();
      } else {
        if (this.countFail > 10) {
          this.$root.infoModal("Totem ou permissão não encontrada");
          this.$router.push("/dashboard/totem/lista-totem");
        } else {
          setTimeout(() => {
            this.verificaExistenciaLocal();
          }, 500);
        }
      }
    },
    goBack() {
      this.$router.push("/dashboard/totem/lista-totem");
    },
  },
  computed: {
    getComprasDeHoje() {
      return this.listaCompras.filter((item) => {
        return moment(item.keep_alive.toDate()).isSame(moment(), "day");
      }).length;
    },
    getCompras() {
      return this.listaCompras;
    },
    getTotem() {
      return this.$store.getters["dashboard/getTotens"].find(
        (item) => item.id == this.$route.params.id
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  @apply tw-p-5 tw-grid tw-grid-cols-1 sm:tw-grid-cols-5 tw-text-sm  tw-border-b tw-border-solid tw-border-gray-200;
  .itemSection {
    @apply tw-text-left;
  }
  /*&:nth-last-child(odd) {
    @apply tw-bg-gray-100;
  }
  &:nth-last-child(even) {
    @apply tw-bg-white;
  }*/
}
.cardCart {
  @apply tw-px-3 tw-py-5 tw-w-full sm:tw-w-4/5 sm:tw-max-w-md  tw-shadow-lg tw-m-2 tw-mx-auto tw-bg-white tw-rounded-lg tw-col-span-1;
}
</style>
